// ADMIN CONSTANTS
export const ADMIN_ACTIVE_ORDER_REQUEST= "ADMIN_ACTIVE_ORDER_REQUEST";
export const ADMIN_ACTIVE_ORDER_SUCCES= "ADMIN_ACTIVE_ORDER_SUCCES";
export const ADMIN_ACTIVE_ORDER_FAIL= "ADMIN_ACTIVE_ORDER_FAIL";


export const ADMIN_COMPLETED_ORDER_REQUEST= "ADMIN_COMPLETED_ORDER_REQUEST";
export const ADMIN_COMPLETED_ORDER_SUCCES= "ADMIN_COMPLETED_ORDER_SUCCES";
export const ADMIN_COMPLETED_ORDER_FAIL= "ADMIN_COMPLETED_ORDER_FAIL";


export const ADMIN_PENDING_REVIEW_REQUEST= "ADMIN_PENDING_REVIEW_REQUEST";
export const ADMIN_PENDING_REVIEW_SUCCES= "ADMIN_PENDING_REVIEW_SUCCES";
export const ADMIN_PENDING_REVIEW_FAIL= "ADMIN_PENDING_REVIEW_FAIL";
export const ADMIN_APPROVED_PENDING_REVIEW = "ADMIN_APPROVED_PENDING_REVIEW";
export const ADMIN_DELETE_PENDING_REVIEW = "ADMIN_DELETE_PENDING_REVIEW";

export const ADMIN_REVIEW_REQUEST= "ADMIN_REVIEW_REQUEST";
export const ADMIN_REVIEW_SUCCES= "ADMIN_REVIEW_SUCCES";
export const ADMIN_REVIEW_FAIL= "ADMIN_REVIEW_FAIL";


export const ADMIN_ALL_USERS_REQUEST= "ADMIN_ALL_USERS_REQUEST";
export const ADMIN_ALL_USERS_SUCCES= "ADMIN_ALL_USERS_SUCCES";
export const ADMIN_ALL_USERS_FAIL= "ADMIN_ALL_USERS_FAIL";

export const ADMIN_FIND_USER_REQUEST= "ADMIN_FIND_USER_REQUEST";
export const ADMIN_FIND_USER_SUCCES= "ADMIN_FIND_USER_SUCCES";
export const ADMIN_FIND_USER_FAIL= "ADMIN_FIND_USER_FAIL";

export const ADMIN_SELECT_DATA = "ADMIN_SELECT_DATA";

export const ADMIN_LANDING_PAGE_REQUEST= "ADMIN_LANDING_PAGE_REQUEST";
export const ADMIN_LANDING_PAGE_SUCCES= "ADMIN_LANDING_PAGE_SUCCES";
export const ADMIN_LANDING_PAGE_FAIL= "ADMIN_LANDING_PAGE_FAIL";