export const SET_REVIEW_DATA = "SET_REVIEW_DATA";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_NUMBER_IN_CART = "INCREMENT_NUMBER_IN_CART";
export const DECREMENT_NUMBER_FROM_CART = "DECREMENT_NUMBER_FROM_CART";
export const REMOVE_ALL_CART = "REMOVE_ALL_CART";
export const CHANGE_SHIPPING_COST = "CHANGE_SHIPPING_COST";
export const CHANGE_ALL_BASKET = "CHANGE_ALL_BASKET";


// WISHLIST CONSTANTS BELOW //
export const CREATE_NEW_WISHLIST = "CREATE_NEW_WISHLIST";
export const CREATE_WISHLIST_NAME = "CREATE_WISHLIST_NAME";
export const DELETE_WISHLIST_ITEM = "DELETE_WISHLIST_ITEM";
export const ADD_SUBITEM_WISHLIST = "ADD_SUBITEM_WISHLIST";
export const DELETE_SUBITEM_WISHLIST = "DELETE_SUBITEM_WISHLIST";
export const INCREMENT_SUBITEM_WISHLIST = "INCREMENT_SUBITEM_WISHLIST";
export const DECREMENT_SUBITEM_WISHLIST = "DECREMENT_SUBITEM_WISHLIST";
export const DELETE_ITEM_WISHLIST = "DELETE_ITEM_WISHLIST";
export const CHANGE_ALL_WISHLIST = "CHANGE_ALL_WISHLIST";